/* eslint-disable no-unused-vars */
enum BROADCAST_STATE {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  EXPIRED = "EXPIRED",
}

enum BROADCAST_TYPE {
  INQUIRY = "BroadcastInquiry",
  STATEMENT = "BroadcastStatement",
}

enum BROADCAST_REQUEST_TYPE {
  INQUIRY = "BroadcastInquiryRequest",
  STATEMENT = "BroadcastStatementRequest",
}

export { BROADCAST_STATE, BROADCAST_TYPE, BROADCAST_REQUEST_TYPE };
