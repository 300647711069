import {
  IQuestionOption,
  nationalitiesOptions,
  QUESTIONNAIRE_ITEM_TYPE,
} from "@app/constants";
import countries from "@app/countries/countries";

import type { AnswerValidationFunction } from "../type";

const isAnOption = (
  options: Array<IQuestionOption> | undefined,
  value: unknown,
) => {
  if (options) {
    return options.some(({ label }) => label === value);
  }
  return false;
};

const isArrayOfOptions = (
  options: Array<IQuestionOption> | undefined,
  values: unknown,
) => {
  if (options && Array.isArray(values)) {
    return values.every((value) =>
      options.some(({ label }) => label === value),
    );
  }
  return false;
};

export const doesAnswerMatchesOptions: AnswerValidationFunction = (
  item,
  answer,
) => {
  switch (item.type) {
    case QUESTIONNAIRE_ITEM_TYPE.CHECKBOX:
      return isArrayOfOptions(item.options, answer);
    case QUESTIONNAIRE_ITEM_TYPE.LOCATION: {
      const countriesOptions = countries.map((country) => ({
        label: country.name,
      }));
      return isAnOption(countriesOptions, answer);
    }
    case QUESTIONNAIRE_ITEM_TYPE.MULTILOCATION: {
      const countriesNameOptions = countries.map((country) => ({
        label: country.name,
      }));
      return isArrayOfOptions(countriesNameOptions, answer);
    }
    case QUESTIONNAIRE_ITEM_TYPE.NATIONALITY: {
      return isAnOption(nationalitiesOptions, answer);
    }
    case QUESTIONNAIRE_ITEM_TYPE.MULTISELECT:
      return isArrayOfOptions(item.options, answer);
    case QUESTIONNAIRE_ITEM_TYPE.NUMBER:
      return true;
    case QUESTIONNAIRE_ITEM_TYPE.RADIO:
      return isAnOption(item.options, answer);
    case QUESTIONNAIRE_ITEM_TYPE.SELECT:
      return isAnOption(item.options, answer);
    case QUESTIONNAIRE_ITEM_TYPE.YESNO:
      return answer === "Yes" || answer === "No";
    // We ignore all optionless item types
    case QUESTIONNAIRE_ITEM_TYPE.LIST:
    case QUESTIONNAIRE_ITEM_TYPE.TABLE:
    case QUESTIONNAIRE_ITEM_TYPE.CATEGORY:
    case QUESTIONNAIRE_ITEM_TYPE.DATE:
    case QUESTIONNAIRE_ITEM_TYPE.EXPLANATION:
    case QUESTIONNAIRE_ITEM_TYPE.TEXT:
    case QUESTIONNAIRE_ITEM_TYPE.TEXTAREA:
    case QUESTIONNAIRE_ITEM_TYPE.EMBEDDED_QUESTIONNAIRE:
      return true;
    // To prevent false negative we fail validation for all non listed item types
    default:
      return false;
  }
};
