const REQUEST_STATE = Object.freeze({
  // TODO: remove after migration
  NOT_STARTED: "NOT_STARTED", // remove after migration
  IN_PROGRESS: "IN_PROGRESS", // remove after migration
  READ: "READ", // remove after migration
  NEW: "NEW", // remove after migration
  ANSWERED: "ANSWERED", // remove after migration
  REVIEWED: "REVIEWED", // remove after migration
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  EXPIRED: "EXPIRED",
});

const REQUEST_TYPE = Object.freeze({
  ACCESS_RIGHTS_REQUEST: "ACCESS_RIGHTS_REQUEST",
  BROADCAST_INQUIRY_REQUEST: "BROADCAST_INQUIRY_REQUEST",
  BROADCAST_STATEMENT_REQUEST: "BROADCAST_STATEMENT_REQUEST",
  DUE_DILIGENCE_REQUEST: "DUE_DILIGENCE_REQUEST",
});

const REQUEST_DIRECTION = Object.freeze({
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING",
});

export { REQUEST_STATE, REQUEST_DIRECTION, REQUEST_TYPE };
