import { getQuestionnaireChecksum } from "@app/constants";
import fs from "fs";
import path from "path";

export const getJsonQuestionnaire = (
  filePath: string,
  questionnaireNameSuffix?: string,
) => {
  const questionnaire = JSON.parse(
    fs.readFileSync(path.join(__dirname, filePath), "utf8"),
  );
  if (questionnaireNameSuffix) {
    questionnaire.label = `${questionnaire.label} - ${questionnaireNameSuffix}`;
    questionnaire.checksum = getQuestionnaireChecksum(questionnaire);
  }
  return questionnaire;
};
