import slugify from "@sindresorhus/slugify";

/**
 * String.hashCode() method
 */
export function toHashCode(str: string) {
  // https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

/**
 * Indicates whether a specified string is null, undefined, or consists only of white-space characters.
 */
export function isNullOrWhiteSpace(str: string) {
  return !str || 0 === str.length || 0 === str.trim().length;
}

/**
 * Indicates whether a specified string is NOT null, undefined, or consists only of white-space characters.
 *
 * The opposite of isNullOrWhiteSpace
 */
export function isNotNullOrWhiteSpace(str: string) {
  return !isNullOrWhiteSpace(str);
}

/**
 * Turns a string into a human readable url (slugified).
 */
export function toUrl(str: string) {
  return slugify(str);
}

export function normalizeString(str: string): string {
  if (!str) return "";
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function alphabeticallySortCaseInsensitive(a: string, b: string) {
  /* Storing case insensitive comparison */
  const comparison = a.toLowerCase().localeCompare(b.toLowerCase());
  /* If strings are equal in case insensitive comparison */
  if (comparison === 0) {
    /* Return case sensitive comparison instead */
    return a.localeCompare(b);
  }
  /* Otherwise return result */
  return comparison;
}
