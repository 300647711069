export * from "./byte-utils";
export * from "./logger";
export * from "./test-image";
export * from "./string-utils";
export * from "./sleep";
import * as datetime from "./datetime-utils";
import * as debug from "./debug-utils";
export * from "./stripEmpty";
export * from "./convertStreamToBase64DataUri";
export * from "./getJsonQuestionnaire";

import { getConfig } from "../config";

const { isProduction } = getConfig();

export function* range(start: number, end: number) {
  for (let i = start; i <= end; i++) {
    yield i;
  }
}

export function warning(condition: any, message: string): asserts condition {
  if (!isProduction) {
    if (condition) {
      console.warn(message);
    }
  }
}

/**
 * A no-op function (it doesn't do anything).
 *
 */

export function noop() {}

export { datetime, debug };
