import dayjs from "dayjs";
import type { ExtensionFactory } from "joi";

import {
  getFormattedValueByType,
  IQuestionnaireItem,
  ItemRule,
  QUESTIONNAIRE_ITEM_TYPE,
} from "@app/constants";

import { createLogger, stripEmpty } from "@app/utils";
import { doesAnswerMatchesOptions } from "../validators/validateAnswerMatchesOptions";
import { messages, messagesKeys } from "../messages";
import { validateItemRule } from "../validators/validateItemRule";

const logger = createLogger("validation:customQuestionnaireAnswer");

export const joiCustomQuestionnaireAnswerExtension: ExtensionFactory = (
  joi,
) => ({
  type: "customQuestionnaireAnswer",
  base: joi.any(),
  messages: {
    ...messages.CQAnswer,
    ...messages.common,
    "any.required": messages.CQAnswer[messagesKeys.CQAnswers.required],
  },
  coerce(rawValue, helpers) {
    const value = rawValue ?? undefined;
    const item: IQuestionnaireItem = helpers.schema.$_getFlag("item");
    if (!item) {
      return {
        errors: [helpers.error(messagesKeys.CQAnswers.noItem)],
      };
    }
    const returnValue = stripEmpty(getFormattedValueByType(item, value));
    if (value && returnValue === undefined) {
      logger.debug("value was stripped during coercion", { item, value });
    }
    if (returnValue === null || !doesAnswerMatchesOptions(item, returnValue)) {
      return {
        errors: [
          helpers.error(messagesKeys.CQAnswers.invalidAnswerType, {
            item: JSON.stringify(item),
            value,
          }),
        ],
      };
    }
    // if undefined validate is not reached
    return { value: returnValue };
  },
  validate(value, helpers) {
    const item: IQuestionnaireItem = helpers.schema.$_getFlag("item");
    if (!item) {
      return {
        errors: helpers.error(messagesKeys.CQAnswers.noItem),
      };
    }
    for (const rule of item.rules ?? []) {
      const err = validateItemRule(item, rule, value, joi);
      if (err) {
        let formatedLimit = rule.value;
        if (item.type === QUESTIONNAIRE_ITEM_TYPE.DATE) {
          formatedLimit = dayjs(rule.value).format("YYYY-MM-DD");
        }
        if (rule.warningOnly) {
          return { value, warns: helpers.warn(err, { limit: formatedLimit }) };
        }
        return { errors: helpers.error(err, { limit: formatedLimit }) };
      }
    }
    return { value };
  },
  rules: {
    item: {
      method(item) {
        // After days of digging into Joi source code since documentation is pretty light on extensions.
        // Please note that if $_setFlag is called without option clone explicitly set to false it is not possible to set multiple flags at once.
        // Downside is you must set each flag everytime otherwise the value will be preserved between validations
        this.$_setFlag("item", item, { clone: false });
        const isRequired = item?.rules?.some(
          (rule: ItemRule) => rule.name === "required",
        );
        this.$_setFlag("presence", isRequired ? "required" : "optional", {
          clone: false,
        });
        return this;
      },
      args: [
        {
          name: "item",
          assert: (item) => QUESTIONNAIRE_ITEM_TYPE[item?.type] !== undefined,
          message: "must be a valid questionnaire item",
        },
      ],
    },
  },
});
