const ACCESS_RIGHTS_REQUEST_DECISION = Object.freeze({
  APPROVED: "APPROVED",
  DENIED: "DENIED",
  MIXED: "MIXED",
});

const ACCESS_RIGHTS_ACTION = Object.freeze({
  ADD: "add",
  REMOVE: "remove",
});

export { ACCESS_RIGHTS_REQUEST_DECISION, ACCESS_RIGHTS_ACTION };
