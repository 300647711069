import { applySnapshot, IQuestionnaireSnapshot } from "../Questionnaire";
import {
  IQuestionnaireQuestion,
  QuestionnaireQuestion,
} from "./QuestionnaireQuestion";

/*
    ChoiceQuestion is a bit of a weird one so let me just explain: 

      Each choice/option that is presented to the user may have some logic connected to it.
      There is the situation where a choice (IChoice) has questions. This means that if this choice is selected,
      we should show more questions that are connected to the choice. (like "you picked other, please explain what other is").
      
      Then the question as a whole can also have questions. This means that if any choice is picked we should show more questions,
      but here they are connected to the question and not to one of the choices.
      The distiction is a bit weird, but good to know anyway.

      A ChoiceQuestion also has a threshold which is used more like a UI trigger that tells the UI that if the number of choices are
      more than the threshold the UI should show a picker (select/dropdown) instead of a simpler input field (radios or checkboxes).
      However, with regards to the logic around choices, if one of choices have "logic" connected to it we will fallback to simpler
      input fields any way since it's impossible for the user to otherwise know to which choice the "more questions" are related. 
*/

export interface IChoice {
  /**
   * What to persist when this option is selected.
   */
  value: string;
  /**
   * What to show in the UI. Value will be used in place of this, if not defined.
   */
  subtitle?: string;
  label?: string;
  /**
   * Questions that should show if THIS option is selected
   */
  questions?: QuestionnaireQuestion[];
}

interface IChoiceQuestion extends IQuestionnaireQuestion {
  /**
   * If true, the question supports selecting/persisting multiple choices.
   */
  isMultipleChoice?: boolean;
  choices: (IChoice | string)[];
  /**
   * Controls when the number of choices available makes the widget go from 'input' to 'picker'. (default is 6)
   */
  threshold?: number;

  /**
   * Questions that should show if ANY option is selected
   */
  questions?: QuestionnaireQuestion[];
}

export class ChoiceQuestion extends QuestionnaireQuestion {
  type = "choice";
  isMultipleChoice: boolean;
  choices: Array<IChoice>;
  threshold: number;
  questions: QuestionnaireQuestion[];
  declare answer?: string | string[];

  constructor({
    isMultipleChoice,
    threshold = 6,
    choices,
    questions,
    ...options
  }: IChoiceQuestion) {
    super(options);

    this.choices = normalizeChoices(choices);
    this.isMultipleChoice = isMultipleChoice ?? false;
    this.threshold = this.getThreshold(threshold);
    this.questions = questions || [];
  }

  clone(): QuestionnaireQuestion {
    return new ChoiceQuestion({
      ...this,
      questions: this.questions.map((question) => question.clone()),
      choices: this.choices.map((choice) => ({
        ...choice,
        questions: choice.questions?.map((question) => question.clone()),
      })),
    });
  }

  answerToString(): string {
    if (!this.isMultipleChoice) {
      return String(this.answer || "Not Set");
    }
    const choices = this.choices.map((choice) => {
      if (typeof choice === "string") {
        const isConfirmed = this.answer?.includes(choice);
        return `${isConfirmed ? "☑️" : "☐"} ${choice}`;
      } else {
        const isConfirmed = this.answer?.includes(choice.value);
        return `${isConfirmed ? "☑️" : "☐"} ${choice.value}`;
      }
    });
    return choices.join("\r\n");
  }

  private getThreshold(threshold: number) {
    if (this.isMultipleChoice) {
      // try to figure out if we any choice has any "logic" connect to it.
      if (this.choices.some((choice) => choice.questions?.length)) {
        return this.choices.length + 1;
      } else {
        return threshold;
      }
    } else {
      return threshold;
    }
  }

  getQuestions(options: { depth: number }): QuestionnaireQuestion[] {
    if (options.depth > 1) {
      // first find all questions that are attached to the choices
      const questionsAttachedToChoices = this.choices.reduce<
        QuestionnaireQuestion[]
      >((questions, choice) => {
        if (Array.isArray(choice.questions)) {
          return [
            ...questions,
            ...choice.questions.reduce<QuestionnaireQuestion[]>(
              (questions, question) => {
                return [...questions, ...question.getQuestions(options)];
              },
              [],
            ),
          ];
        } else {
          return questions;
        }
      }, []);

      // return all possible questions attached to this question
      return this.questions.reduce<QuestionnaireQuestion[]>(
        (questions, question) => {
          return [...questions, ...question.getQuestions(options)];
        },
        [this, ...questionsAttachedToChoices],
      );
    } else {
      return [this];
    }
  }

  onSnapshotApplied(snapshot: IQuestionnaireSnapshot): void {
    // this whole code tries to handle the "logic" around choices mentioned above,
    // and is applied after we have applied the answers to the question as a whole
    if (Array.isArray(this.choices)) {
      const choices = this.choices.filter((choice) => {
        if (Array.isArray(this.answer)) {
          return this.answer.includes(choice.value);
        } else {
          return choice.value === this.answer;
        }
      });
      // if the choice is one of the answers,
      // try to apply the answers to the questions connected to the choice too.
      for (const choice of choices)
        if (choice?.questions?.length) {
          applySnapshot(choice.questions, snapshot);
        }
    }

    // try to apply answers to my question too
    if (this.answer && this.questions.length > 0) {
      applySnapshot(this.questions, snapshot);
    }
  }

  preProcessSnapshotAnswer(answer?: any) {
    if (answer) {
      if (this.isMultipleChoice && Array.isArray(answer)) {
        this.answer = answer;
      } else {
        this.answer = String(answer);
      }
    }
  }

  public static Choice(value: string, choice: Omit<IChoice, "value">): IChoice {
    return {
      value,
      ...choice,
    };
  }
}

function normalizeChoices(choices: (string | IChoice)[]): IChoice[] {
  return choices.map<IChoice>((choice) => {
    if (typeof choice === "string") {
      return {
        value: choice,
      };
    } else {
      return choice;
    }
  });
}

/**
 * Turns a list of strings to a list of choices
 */
export function toChoices(values: string[]) {
  return values.map<IChoice>((x) => ({ value: x }));
}
