const CAMPAIGN_STATE = {
  NOT_STARTED: "NOT_STARTED",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  EXPIRED: "EXPIRED",
};

const CAMPAIGN_EVENT = {
  RECIPIENT_ADDED: "RECIPIENT_ADDED",
  RECIPIENT_CANCELED: "RECIPIENT_CANCELED",
  RECIPIENT_DELETED: "RECIPIENT_DELETED",
  CHANGE_DEADLINE_ALL: "CHANGE_DEADLINE_ALL",
  CHANGE_DEADLINE: "CHANGE_DEADLINE",
  CANCELED: "CANCELED",
};

const REQUIREMENT_TYPE = {
  QUESTION: "question",
  DOCUMENT: "document",
  CUSTOM: "custom",
};

const REPORT_STATE = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

const REPORT_EVENT = {
  ANALYSIS_ADDED: "ANALYSIS_ADDED",
  COMPLETE: "COMPLETE",
} as const;

const REQUEST_EVENT = {
  CONFIRM_REQUIREMENT: "CONFIRM_REQUIREMENT",
  REJECT_REQUIREMENT: "REJECT_REQUIREMENT",
  UNDO_CONFIRM_REQUIREMENT: "UNDO_CONFIRM_REQUIREMENT",
  COMPLETE: "COMPLETE",
  EXPIRE: "EXPIRE",
  CHANGE_DEADLINE: "CHANGE_DEADLINE",
} as const;

const REPORT_SPECIAL_APPROVAL = {
  NOT_NEEDED: "NOT_NEEDED",
  CEO: "CEO",
  BOARD_OF_DIRECTORS: "BOARD_OF_DIRECTORS",
} as const;

const REPORT_RISK_ENTITIES = {
  PEP: "Politically Exposed Person / Government Offical",
  SAN: "Sanctioned",
  AM: "Adverse Media Person",
} as const;

function getRiskEntities() {
  return Object.values(REPORT_RISK_ENTITIES);
}

const DUE_DILIGENCE = {
  CAMPAIGN_EVENT,
  CAMPAIGN_STATE,
  getRiskEntities,
  REPORT_EVENT,
  REPORT_RISK_ENTITIES,
  REPORT_SPECIAL_APPROVAL,
  REPORT_STATE,
  REQUEST_EVENT,
  REQUIREMENT_TYPE,
};

export { DUE_DILIGENCE };
